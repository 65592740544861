import Vue from "vue";
const state = {
  funnelData: {},
  cities: [
    {
      id: "city_1",
      name: "Madrid",
      code: "ES-M",
      country: "ES",
    },
    {
      id: "city_2",
      name: "Barcelona",
      code: "ES-B",
      country: "ES",
    },
    {
      id: "city_3",
      name: "Valencia",
      code: "ES-V",
      country: "ES",
    },
    {
      id: "city_4",
      name: "Sevilla",
      code: "ES-S",
      country: "ES",
    },
    {
      id: "city_5",
      name: "Malaga",
      code: "ES-ML",
      country: "ES",
    },
    {
      id: "city_6",
      name: "Lisboa",
      code: "PT-L",
      country: "PT",
    },
    {
      id: "city_7",
      name: "Oporto",
      code: "PT-O",
      country: "PT",
    },
    {
      id: "city_8",
      name: "Paris",
      code: "FR-P",
      country: "FR",
    },
  ],
};

/************ MUTATIONS ************/
const mutations = {
  updatefunnelData: (state, data) => {
    Object.entries(data).forEach(([key, value]) =>
      Vue.set(state.funnelData, key, value)
    );
  },
};

/************ ACTIONS ************/

const actions = {};

/************ Export ************/

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
