const navigatorLocale = navigator.language.toLowerCase().slice(0, 2) || "en-US";

const formatCurrency = (
  number = 0,
  currencyCode = "USD",
  minDecimal = 2,
  maxDecimal = 2
) =>
  new Intl.NumberFormat(navigatorLocale, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: minDecimal,
    maximumFractionDigits: maxDecimal,
  }).format(number);

const numberFormatter = (Vue) => {
  // Add here new prototype
  Vue.prototype.$formatCurrency = formatCurrency;

  // Add here new filters
  Vue.filter("formatCurrency", formatCurrency);
};

export default numberFormatter;
export { formatCurrency };
