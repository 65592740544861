import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import contracts from "./modules/contracts";
import inventory from "./modules/inventory";
import navigation from "./modules/navigation";
import orders from "./modules/orders";
import languages from "./modules/languages";
import funnel from "./modules/funnel";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    contracts,
    inventory,
    navigation,
    orders,
    languages,
    funnel,
  },
});
