import { getLanguageCode } from "@/utils/language";

const state = {
  siteLanguage: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
};
const mutations = {
  setSiteLanguage(state, language) {
    state.siteLanguage = language;
  },
};

const actions = {
  getSiteLanguage({ commit }, payload) {
    // Get site location
    const siteLanguage = payload || getLanguageCode(location.hostname);
    commit("setSiteLanguage", siteLanguage);
    return siteLanguage;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
