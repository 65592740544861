const spainProvinces = [
  { name: "A Coruña" },
  { name: "Álava" },
  { name: "Albacete" },
  { name: "Alicante" },
  { name: "Almería" },
  { name: "Asturias" },
  { name: "Ávila" },
  { name: "Badajoz" },
  { name: "Islas Baleares" },
  { name: "Barcelona" },
  { name: "Burgos" },
  { name: "Cáceres" },
  { name: "Cádiz" },
  { name: "Cantabria" },
  { name: "Castellón" },
  { name: "Ceuta" },
  { name: "Ciudad Real" },
  { name: "Córdoba" },
  { name: "Cuenca" },
  { name: "Girona" },
  { name: "Granada" },
  { name: "Guadalajara" },
  { name: "Guipúzcoa" },
  { name: "Huelva" },
  { name: "Huesca" },
  { name: "Jaén" },
  { name: "La Rioja" },
  { name: "Las Palmas" },
  { name: "León" },
  { name: "Lleida" },
  { name: "Lugo" },
  { name: "Madrid" },
  { name: "Málaga" },
  { name: "Melilla" },
  { name: "Murcia" },
  { name: "Navarra" },
  { name: "Orense" },
  { name: "Palencia" },
  { name: "Pontevedra" },
  { name: "Salamanca" },
  { name: "Segovia" },
  { name: "Sevilla" },
  { name: "Soria" },
  { name: "Tarragona" },
  { name: "Santa Cruz de Tenerife" },
  { name: "Teruel" },
  { name: "Toledo" },
  { name: "Valencia" },
  { name: "Valladolid" },
  { name: "Vizcaya" },
  { name: "Zamora" },
  { name: "Zaragoza" },
];

export default spainProvinces;
