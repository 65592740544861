<template>
  <div>
    <Body></Body>
  </div>
</template>

<script>
import Body from "@/components/B2bFunnelBudget/B2bBody.vue";

export default {
  components: {
    Body,
  },
};
</script>
