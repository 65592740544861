import Vue from "vue";
import VueRouter from "vue-router";
import Checkout from "@/views/Checkout.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import FunnelBudget from "@/views/FunnelBudget.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   component: Main,
  //   children: [
  //     {
  //       path: "",
  //       component: Home,
  //       meta: { requiresAuth: true },
  //     },
  //     {
  //       path: "/inventory",
  //       component: Inventory,
  //       meta: { requiresAuth: true },
  //     },
  //     {
  //       path: "/documents",
  //       component: Documents,
  //       meta: { requiresAuth: true },
  //     },
  //     {
  //       path: "/subscription",
  //       component: Subscription,
  //       meta: { requiresAuth: true },
  //     },
  //     {
  //       path: "/configuration",
  //       component: Configuration,
  //       children: [
  //         {
  //           path: "",
  //           component: MyAccount,
  //           meta: { requiresAuth: true },
  //         },
  //         {
  //           path: "/configuration/billing",
  //           component: Billing,
  //           meta: { requiresAuth: true },
  //         },
  //       ],
  //     },
  //     {
  //       path: "/request/:type?",
  //       component: Request,
  //       meta: { requiresAuth: true },
  //     },
  //     {
  //       path: "/myrequests",
  //       component: MyRequests,
  //       meta: { requiresAuth: true },
  //     },
  //   ],
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: Login,
  // },
  // {
  //   path: "/recover-password/:uid/:token",
  //   name: "AccessRecoveryPassword",
  //   component: AccessRecoveryPassword,
  // },
  // {
  //   path: "/confirmation",
  //   name: "Confirmation",
  //   component: Confirmation,
  // },
  // {
  //   path: "/access/activate/:uid/:token/",
  //   name: "ActivateAccount",
  //   component: ActivateAccount,
  // },
  {
    path: "/pay/:key/",
    name: "Payment",
    component: Checkout,
  },
  {
    path: "/payment/:site/:key/",
    name: "PaymentLink",
    component: Checkout,
  },
  {
    path: "/booking/:deal_id/",
    name: "FunnelBudget",
    component: FunnelBudget,
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

// const user_id = localStorage.getItem("user_id");

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach(async (to, from, next) => {
//   store.commit("navigation/setCurrentPage", to.path);
//   if (to.meta.requiresAuth) {
//     if (store.state.auth.profile) {
//       next();
//     } else {
//       // Check if we have stored tokens in localstorage and try performing an authenticated call using it
//       // If it is an authenticated user, try getting first the token from localStorage
//       const accessToken = Vue.prototype.$getToken("access-token");

//       if (accessToken) {
//         const userId = Vue.prototype.$decodeToken(accessToken).user_id;
//         // Try to get user data
//         store.dispatch("auth/getUser", userId).then(() => {
//           next();
//         });
//       } else {
//         next("/login");
//       }
//     }
//   } else {
//     next();
//   }
// });

export default router;
