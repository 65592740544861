import * as Sentry from "@sentry/vue";

/**
 * Send to sentry an exception
 * @param {Error} exception
 */
export function sentryException(exception) {
  if (process.env.VUE_APP_ENVIRONMENT !== "development") {
    if (Sentry != null) {
      Sentry.captureException(exception);
    }
  }
}

/**
 * Send a message to sentry
 * @param {String} message
 */
export function sentryMessage(message) {
  if (process.env.VUE_APP_ENVIRONMENT !== "development") {
    if (Sentry != null) {
      Sentry.captureMessage(message);
    }
  }
}
