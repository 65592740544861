import api from "@/api";
const state = {
  count: 0,
  total_pages: 1,
  actual_page: 1,
  ordersList: [],
};
const mutations = {
  getListOrdersSuccess(state, orders) {
    state.count = orders.count;
    state.total_pages = orders.total_pages;
    state.ordersList = orders.results;
    state.actual_page = 1;
  },
  addOrdersListSuccess(state, orders) {
    state.ordersList = [...state.ordersList, ...orders.results];
    state.actual_page = orders.page;
  },
};
const actions = {
  async getOrdersList({ commit }, payload) {
    const response = await api.orders.getOrdersList(payload.contractId);
    commit("getListOrdersSuccess", response);
    return response;
  },
  async getNextOrdersPage({ commit }, payload) {
    const response = await api.orders.getOrdersList(payload.contractId);
    commit("addOrdersListSuccess", { ...response, page: payload.page });
    return response;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
