import api from "@/api";

const state = {
  selectedContract: "",
  contractsList: [],
  contractData: null,
  invoicesList: null,
  countriesList: [],
};
const mutations = {
  contractsListSuccess(state, contracts) {
    state.contractsList = contracts;

    if (contracts.length) {
      state.selectedContract = contracts[0].id;
    }
  },
  setSelectedContract(state, id) {
    state.selectedContract = id;
  },
  setContractData(state, contractData) {
    state.contractData = contractData;
  },
  setInvoicesList(state, invoices) {
    state.invoicesList = invoices;
  },
  setCountriesList(state, countries) {
    state.countriesList = countries;
  },
  resetContracts(state) {
    state.selectedContract = "";
    state.contractsList = [];
    state.contractData = null;
    state.invoicesList = null;
    state.countriesList = [];
  },
};
const actions = {
  async getContracts({ commit }) {
    const response = await api.contracts.getContracts();
    commit("contractsListSuccess", response);
    return response;
  },
  async getContractById({ commit }, contractId) {
    const response = await api.contracts.getContractById(contractId);
    commit("setContractData", response);
    return response;
  },
  async getInvoicesList({ commit }, id) {
    const response = await api.contracts.getInvoicesList(id);
    commit("setInvoicesList", response);
  },
  async getCountriesList({ commit }) {
    const response = await api.public.getCountriesList();
    commit("setCountriesList", response.data);
    return response;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
