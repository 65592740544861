import api from "@/api";
import Vue from "vue";

const state = {
  profile: null,
  paymentMethod: null,
};
const mutations = {
  loginSuccess(state, data) {
    state.profile = data.user;
  },
  profileSuccess(state, profile) {
    state.profile = profile;
  },
  paymentMethodSuccess(state, payment) {
    state.paymentMethod = payment;
  },
  updateUser(state, profile) {
    state.profile = { ...state.profile, ...profile };
  },
  resetAuth(state) {
    state.profile = null;
  },
};

const actions = {
  async login({ commit }, input) {
    const response = await api.public.login(input);

    // Store tokens
    Vue.prototype.$saveAccessToken(response.data.access_token);
    Vue.prototype.$saveRefreshToken(response.data.refresh_token);

    commit("loginSuccess", response.data);
    return response;
  },
  async refreshToken(_, payload) {
    const response = await api.auth.refreshAccessToken(payload);

    // Store token
    Vue.prototype.$saveAccessToken(response.access);
    return response;
  },
  async getUser({ commit }, user_pk) {
    const response = await api.auth.getUser(user_pk);
    commit("profileSuccess", response);
    return response;
  },
  async updateUser({ commit }, input) {
    const response = await api.auth.updateUser(input);
    commit("updateUser", response);
    return response;
  },
  async getPaymentMethods({ commit }, user_pk) {
    const response = await api.auth.getPaymentMethods(user_pk);
    commit("paymentMethodSuccess", response);
    return response;
  },
  logout({ commit }) {
    Vue.prototype.$removeTokens();
    commit("resetAuth");
    window.location.reload();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
