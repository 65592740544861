<template>
  <div class="mainbox">
    <img src="@/assets/404.png" />
    <h1>{{ $t("page_not_found.title") }} <span>😞</span></h1>
    <div>
      {{ $t("page_not_found.text") }}
    </div>
    <br />
    <button @click.prevent="$router.push('/')" v-if="isAuthenticated()">
      {{ $t("page_not_found.button") }}
    </button>
  </div>
</template>

<script>
import Vue from "vue";
import { sentryMessage } from "@/services/sentry";

export default {
  name: "PageNotFound",
  created() {
    sentryMessage(`404 Path: ${this.$route.path}`);
  },
  methods: {
    isAuthenticated() {
      const token = Vue.prototype.$getToken("access-token");

      try {
        Vue.prototype.$decodeToken(token).user_id;
      } catch (error) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/app";
* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  font-size: 15px;
  line-height: 1.4em;
  color: #525f7f;
}

.mainbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 0.1rem);
  margin: 0 auto;
  padding: 0 1rem;
  text-align: center;
  background-color: #e7edfa73;

  h1 {
    font-size: 2rem;
    margin-top: 2rem;
  }

  div {
    font-size: 1.1rem;
    font-weight: 500;
  }

  span {
    font-size: 1.7rem;
  }

  button {
    font-size: 0.875rem;
    font-weight: bold;
    height: 38px;
    width: 118px;
    color: #fff;
    border: 1px solid #4760ff;
    background-color: #4760ff;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  button:hover {
    background-color: #3f55e0;
  }
}
</style>
