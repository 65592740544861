import {
  decodeToken,
  getToken,
  saveToken,
  removeToken,
  removeAuthTokens,
} from "./authentication.js";

const VueAuthentication = (Vue) => {
  // Add here new prototype
  Vue.prototype.$decodeToken = decodeToken;
  Vue.prototype.$getToken = getToken;
  Vue.prototype.$saveAccessToken = (tokenValue) => {
    return saveToken("access-token", tokenValue);
  };
  Vue.prototype.$saveRefreshToken = (tokenValue) => {
    return saveToken("refresh-token", tokenValue);
  };
  Vue.prototype.$removeToken = removeToken;
  Vue.prototype.$removeTokens = removeAuthTokens;
};

export default VueAuthentication;
