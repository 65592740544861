import { helpers } from "vuelidate/lib/validators";
import zipCodes from "@/utils/zipCodes";
import { isValidPhoneNumber } from "libphonenumber-js";

// Custom validation
const validateVat = async (value, vm) => {
  return (
    !helpers.req(value) || (await vm.validateVatNumber(value)) !== "INVALID"
  );
};

/**
 * Validate Zip Code according to the selected country.
 */
const validateZipCode = (value, vm) => {
  return vm.country
    ? helpers.regex("zipCodes", new RegExp(zipCodes[vm.country]))(value)
    : true;
};

/**
 * Validate phone number
 */

const validatePhoneNumber = (value) => {
  return isValidPhoneNumber(value);
};

export { validateVat, validateZipCode, validatePhoneNumber };
