// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
import { initializeApp } from "firebase/app";
import "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB49FKJQTJttIfBLEALWOaZpp9q0TYIZ7Y",
  authDomain: "box2box-cloud.firebaseapp.com",
  projectId: "box2box-cloud",
  storageBucket: process.env.VUE_APP_FIREBASE_BUCKET_PATH,
  messagingSenderId: "1031172290510",
  appId: "1:1031172290510:web:64a979432be6b0d4175d9f",
  measurementId: "G-24KEKG4SK6",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
