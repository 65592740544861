const domains = {
  "box2box.es": "es",
  "box2box.io": "fr",
  "box2box.net": "pt",
};

const cleanURL = (url) => {
  return url.split(".").slice(-2).join(".") || url;
};

export const getLanguageCode = (domain) => {
  return domains[cleanURL(domain)] || process.env.VUE_APP_I18N_FALLBACK_LOCALE;
};
