export default {
  "ES-M": {
    name: "Madrid",
    1: {
      storage: "29€",
      pickup: "GRATIS",
      delivery: "29€",
    },
    2: {
      storage: "49€",
      pickup: "GRATIS",
      delivery: "49€",
    },
    3: {
      storage: "65€",
      pickup: "GRATIS",
      delivery: "64€",
    },
    4: {
      storage: "75€",
      pickup: "GRATIS",
      delivery: "79€",
    },
    5: {
      storage: "89€",
      pickup: "GRATIS",
      delivery: "89€",
    },
    6: {
      storage: "105€",
      pickup: "GRATIS",
      delivery: "99€",
    },
    "7,5": {
      storage: "125€",
      pickup: "39€",
      delivery: "139€",
    },
    9: {
      storage: "149€",
      pickup: "99€",
      delivery: "199€",
    },
    custom: {
      storage: "Personalizado",
      pickup: "Personalizado",
      delivery: "Personalizado",
    },
    days: [2, 1],
  },
  "ES-B": {
    name: "Barcelona",
    1: {
      storage: "29€",
      pickup: "GRATIS",
      delivery: "29€",
    },
    2: {
      storage: "49€",
      pickup: "GRATIS",
      delivery: "49€",
    },
    3: {
      storage: "65€",
      pickup: "GRATIS",
      delivery: "64€",
    },
    4: {
      storage: "75€",
      pickup: "GRATIS",
      delivery: "79€",
    },
    5: {
      storage: "89€",
      pickup: "GRATIS",
      delivery: "89€",
    },
    6: {
      storage: "105€",
      pickup: "GRATIS",
      delivery: "99€",
    },
    "7,5": {
      storage: "125€",
      pickup: "39€",
      delivery: "139€",
    },
    9: {
      storage: "149€",
      pickup: "99€",
      delivery: "199€",
    },
    custom: {
      storage: "Personalizado",
      pickup: "Personalizado",
      delivery: "Personalizado",
    },
    days: [7, 1],
  },
  "ES-ML": {
    name: "Malaga",
    1: {
      storage: "25€",
      pickup: "GRATIS",
      delivery: "29€",
    },
    2: {
      storage: "45€",
      pickup: "GRATIS",
      delivery: "49€",
    },
    3: {
      storage: "55€",
      pickup: "GRATIS",
      delivery: "64€",
    },
    4: {
      storage: "69€",
      pickup: "GRATIS",
      delivery: "79€",
    },
    5: {
      storage: "79€",
      pickup: "GRATIS",
      delivery: "89€",
    },
    6: {
      storage: "89€",
      pickup: "GRATIS",
      delivery: "99€",
    },
    "7,5": {
      storage: "105€",
      pickup: "39€",
      delivery: "139€",
    },
    9: {
      storage: "125€",
      pickup: "99€",
      delivery: "199€",
    },
    custom: {
      storage: "Personalizado",
      pickup: "Personalizado",
      delivery: "Personalizado",
    },
    days: [7, 1],
  },
  "ES-V": {
    name: "Valencia",
    1: {
      storage: "25€",
      pickup: "GRATIS",
      delivery: "29€",
    },
    2: {
      storage: "45€",
      pickup: "GRATIS",
      delivery: "49€",
    },
    3: {
      storage: "55€",
      pickup: "GRATIS",
      delivery: "64€",
    },
    4: {
      storage: "69€",
      pickup: "GRATIS",
      delivery: "79€",
    },
    5: {
      storage: "79€",
      pickup: "GRATIS",
      delivery: "89€",
    },
    6: {
      storage: "89€",
      pickup: "GRATIS",
      delivery: "99€",
    },
    "7,5": {
      storage: "105€",
      pickup: "39€",
      delivery: "139€",
    },
    9: {
      storage: "125€",
      pickup: "99€",
      delivery: "199€",
    },
    custom: {
      storage: "Personalizado",
      pickup: "Personalizado",
      delivery: "Personalizado",
    },
    days: [7, 1],
  },
  "ES-S": {
    name: "Sevilla",
    1: {
      storage: "25€",
      pickup: "GRATIS",
      delivery: "29€",
    },
    2: {
      storage: "45€",
      pickup: "GRATIS",
      delivery: "49€",
    },
    3: {
      storage: "55€",
      pickup: "GRATIS",
      delivery: "64€",
    },
    4: {
      storage: "69€",
      pickup: "GRATIS",
      delivery: "79€",
    },
    5: {
      storage: "79€",
      pickup: "GRATIS",
      delivery: "89€",
    },
    6: {
      storage: "89€",
      pickup: "GRATIS",
      delivery: "99€",
    },
    "7,5": {
      storage: "105€",
      pickup: "39€",
      delivery: "139€",
    },
    9: {
      storage: "125€",
      pickup: "99€",
      delivery: "199€",
    },
    custom: {
      storage: "Personalizado",
      pickup: "Personalizado",
      delivery: "Personalizado",
    },
    days: [7, 1],
  },
  "PT-L": {
    name: "Lisboa",
    1: {
      storage: "29€",
      pickup: "GRATIS",
      delivery: "29€",
    },
    2: {
      storage: "45€",
      pickup: "GRATIS",
      delivery: "45€",
    },
    3: {
      storage: "59€",
      pickup: "GRATIS",
      delivery: "59€",
    },
    4: {
      storage: "69€",
      pickup: "GRATIS",
      delivery: "69€",
    },
    5: {
      storage: "79€",
      pickup: "GRATIS",
      delivery: "79€",
    },
    6: {
      storage: "95€",
      pickup: "GRATIS",
      delivery: "89€",
    },
    "7,5": {
      storage: "105€",
      pickup: "39€",
      delivery: "139€",
    },
    9: {
      storage: "119€",
      pickup: "99€",
      delivery: "199€",
    },
    custom: {
      storage: "Personalizado",
      pickup: "Personalizado",
      delivery: "Personalizado",
    },
    days: [7, 1],
  },
  "PT-O": {
    name: "Oporto",
    1: {
      storage: "29€",
      pickup: "GRATIS",
      delivery: "29€",
    },
    2: {
      storage: "45€",
      pickup: "GRATIS",
      delivery: "45€",
    },
    3: {
      storage: "59€",
      pickup: "GRATIS",
      delivery: "59€",
    },
    4: {
      storage: "69€",
      pickup: "GRATIS",
      delivery: "69€",
    },
    5: {
      storage: "79€",
      pickup: "GRATIS",
      delivery: "79€",
    },
    6: {
      storage: "95€",
      pickup: "GRATIS",
      delivery: "89€",
    },
    "7,5": {
      storage: "105€",
      pickup: "39€",
      delivery: "139€",
    },
    9: {
      storage: "119€",
      pickup: "99€",
      delivery: "199€",
    },
    custom: {
      storage: "Personalizado",
      pickup: "Personalizado",
      delivery: "Personalizado",
    },
    days: [7, 1],
  },
  "FR-P": {
    name: "Paris",
    1: {
      storage: "39€",
      pickup: "19€",
      delivery: "39€",
    },
    2: {
      storage: "69€",
      pickup: "39€",
      delivery: "79€",
    },
    3: {
      storage: "89€",
      pickup: "59€",
      delivery: "119€",
    },
    4: {
      storage: "109€",
      pickup: "89€",
      delivery: "179€",
    },
    5: {
      storage: "129€",
      pickup: "119€",
      delivery: "219€",
    },
    6: {
      storage: "149€",
      pickup: "189€",
      delivery: "289€",
    },
    "7,5": {
      storage: "180€",
      pickup: "269€",
      delivery: "369€",
    },
    9: {
      storage: "215€",
      pickup: "379€",
      delivery: "479€",
    },
    custom: {
      storage: "Personnalisé",
      pickup: "Personnalisé",
      delivery: "Personnalisé",
    },
    days: [7, 1],
  },
};
