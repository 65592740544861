/* Helper methods to manage auth accordingly */

import jwt_decode from "jwt-decode";
/**
 * Decode Json Web Token to fetch encoded data easily
 */
const decodeToken = (token) => {
  return jwt_decode(token);
};

const getToken = (tokenName) => {
  return localStorage.getItem(tokenName || "access_token");
};

const saveToken = (tokenName, value) => {
  localStorage.setItem(tokenName, value);
};

const removeToken = (tokenName) => {
  localStorage.removeItem(tokenName);
};

const removeAuthTokens = () => {
  removeToken("access-token");
  removeToken("refresh-token");
};

export { decodeToken, getToken, saveToken, removeToken, removeAuthTokens };
