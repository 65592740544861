const state = {
  currentPage: "",
};
const mutations = {
  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
};
