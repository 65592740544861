import api from "@/api";
const state = {
  count: 0,
  total_pages: 1,
  actual_page: 1,
  inventoryList: [],
  inventoryChecked: [],
};
const mutations = {
  getInventoryListSuccess(state, inventory) {
    state.count = inventory.count;
    state.total_pages = inventory.total_pages;
    state.inventoryList = inventory.results;
    state.actual_page = 1;
  },
  addInventoryListSuccess(state, inventory) {
    state.inventoryList = [...state.inventoryList, ...inventory.results];
    state.actual_page = inventory.page;
  },
  resetInventory(state) {
    state.total_pages = 1;
    state.actual_page = 1;
    state.count = 0;
    state.inventoryList = [];
    state.inventoryChecked = [];
  },
  setInventoryChecked(state, inventory) {
    state.inventoryChecked = inventory;
  },
};
const actions = {
  async getInventory({ commit }, payload) {
    const response = await api.inventory.getInventory(
      payload.contractId,
      payload.page,
      payload.ordering
    );
    commit("getInventoryListSuccess", response);
    return response;
  },
  async getNextInventoryPage({ commit }, payload) {
    const response = await api.inventory.getInventory(
      payload.contractId,
      payload.page,
      payload.ordering
    );
    commit("addInventoryListSuccess", { ...response, page: payload.page });
    return response;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
