/**
 * Helper methods to get the right payments configuration for chargebee/adyen according to environments
 */

import AdyenCheckout from "@adyen/adyen-web";

const configuration = {
  es: {
    adyenEnvironment: process.env.VUE_APP_ADYEN_ENVIRONMENT,
    gatewayUrl: process.env.VUE_APP_API_GATEWAY_URL,
    chargebeeSite: process.env.VUE_APP_CHARGEBEE_SITE_ES,
    chargebeeKey: process.env.VUE_APP_CHARGEBEE_PUBLISHABLE_KEY_ES,
    adyenKey: process.env.VUE_APP_ADYEN_CLIENT_KEY_ES,
  },
  io: {
    adyenEnvironment: process.env.VUE_APP_ADYEN_ENVIRONMENT,
    gatewayUrl: process.env.VUE_APP_API_GATEWAY_URL,
    chargebeeSite: process.env.VUE_APP_CHARGEBEE_SITE_FR,
    chargebeeKey: process.env.VUE_APP_CHARGEBEE_PUBLISHABLE_KEY_FR,
    adyenKey: process.env.VUE_APP_ADYEN_CLIENT_KEY_FR,
  },
  net: {
    adyenEnvironment: process.env.VUE_APP_ADYEN_ENVIRONMENT,
    gatewayUrl: process.env.VUE_APP_API_GATEWAY_URL,
    chargebeeSite: process.env.VUE_APP_CHARGEBEE_SITE_PT,
    chargebeeKey: process.env.VUE_APP_CHARGEBEE_PUBLISHABLE_KEY_PT,
    adyenKey: process.env.VUE_APP_ADYEN_CLIENT_KEY_PT,
  },
  it: {
    adyenEnvironment: process.env.VUE_APP_ADYEN_ENVIRONMENT,
    gatewayUrl: process.env.VUE_APP_API_GATEWAY_URL,
    chargebeeSite: process.env.VUE_APP_CHARGEBEE_SITE_ES,
    chargebeeKey: process.env.VUE_APP_CHARGEBEE_PUBLISHABLE_KEY_ES,
    adyenKey: process.env.VUE_APP_ADYEN_CLIENT_KEY_ES,
  },
  fr: {
    adyenEnvironment: process.env.VUE_APP_ADYEN_ENVIRONMENT,
    gatewayUrl: process.env.VUE_APP_API_GATEWAY_URL,
    chargebeeSite: process.env.VUE_APP_CHARGEBEE_SITE_FR,
    chargebeeKey: process.env.VUE_APP_CHARGEBEE_PUBLISHABLE_KEY_FR,
    adyenKey: process.env.VUE_APP_ADYEN_CLIENT_KEY_FR,
  },
  pt: {
    adyenEnvironment: process.env.VUE_APP_ADYEN_ENVIRONMENT,
    gatewayUrl: process.env.VUE_APP_API_GATEWAY_URL,
    chargebeeSite: process.env.VUE_APP_CHARGEBEE_SITE_PT,
    chargebeeKey: process.env.VUE_APP_CHARGEBEE_PUBLISHABLE_KEY_PT,
    adyenKey: process.env.VUE_APP_ADYEN_CLIENT_KEY_PT,
  },
};

export default class Payment {
  constructor(languageSite = null, currentSite = null) {
    this.domainSite = currentSite || this.inferDomainSite();
    this.configuration = configuration[this.domainSite];
    window.logger.info("CONFIG ", this.configuration);
    this.chargebeeInstance = null;
    this.adyenDefaultConfiguration = {
      locale: languageSite || "en", // The shopper's locale. For a list of supported locales, see https://docs.adyen.com/online-payments/web-components/localization-components.
      environment: this.configuration.adyenEnvironment, // When you're ready to accept live payments, change the value to one of our live environments https://docs.adyen.com/online-payments/components-web#testing-your-integration.
      clientKey: this.configuration.adyenKey, // Your client key. To find out how to generate one, see https://docs.adyen.com/development-resources/client-side-authentication. Web Components versions before 3.10.1 use originKey instead of clientKey.
      paymentMethodsConfiguration: {
        card: {
          styles: {
            base: {
              color: "#525f7f",
              fontWeight: "500",
              fontSize: "0.81rem",
              lineHeight: "1rem",
            },
            placeholder: {
              color: "#525f7f",
              fontSize: "0.81rem",
            },
            error: {
              color: "#001b2b",
            },
          },
          name: "Credit or debit card",
        },
      },
    };

    // Init parts
    this.initChargebee();
  }

  /**
   * Deprecated. It remains by compatibility backwards, but with the new redirection
   * is not needed, because the domain site will be set by parameter.
   */
  inferDomainSite() {
    const domainHostname = window.location.hostname.split(".").pop();
    const isValidDomain = Object.keys(configuration).includes(domainHostname);

    if (process.env.VUE_APP_ENVIRONMENT !== "production") {
      return "es";
    }

    if (isValidDomain) {
      return domainHostname;
    }

    throw new Error("Invalid domain");
  }

  /**
   * Handle chargebee initialize smartly recycling the existing instance only if does not exist now
   */
  initChargebee() {
    if (window.Chargebee && window.Chargebee.inited) {
      this.chargebeeInstance = window.Chargebee.getInstance();
    } else {
      this.chargebeeInstance = window.Chargebee.init({
        site: this.configuration.chargebeeSite,
        publishableKey: this.configuration.chargebeeKey,
      });
    }

    return this.chargebeeInstance;
  }

  initAdyen(additionalConfiguration) {
    const adyenConfiguration = {
      ...this.adyenDefaultConfiguration,
      ...additionalConfiguration,
    };
    return AdyenCheckout(adyenConfiguration);
  }

  getChargebeeInstance() {
    return this.chargebeeInstance;
  }
}
