const navigatorLocale = navigator.language.toLowerCase().slice(0, 2) || "en-US";

const formatDate = (date, options) => {
  if (!options) {
    options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
  }

  if (typeof date !== Date) {
    date = new Date(date);
  }

  return new Intl.DateTimeFormat(navigatorLocale, options).format(date);
};

const dateFormatter = (Vue) => {
  // Add here new prototype
  Vue.prototype.$formatDate = formatDate;

  // Add here new filters
  Vue.filter("formatDate", formatDate);
};

export default dateFormatter;
export { formatDate };
