<template>
  <div>
    <transition name="fade" appear>
      <b2b-payment-link-contract
        v-if="showModal"
        @clicked="showModal = false"
      />
    </transition>

    <div v-show="!loading">
      <main id="main" class="checkout" v-if="shouldDisplayForm">
        <div id="pay-detail">
          <div class="banner" v-if="shouldDisplayBanner">
            <p>{{ $t("checkout.banner.header") }}</p>
            <p>{{ $t("checkout.banner.content") }}</p>
          </div>
          <div class="detail-wrapper">
            <h1 class="logo">
              <span>box 2 box</span>
            </h1>
            <hr />
            <div class="amount-data">
              <div class="pay">{{ $t("checkout.pay") }}</div>
              <div class="amount">
                {{ (metadata.deposit / 100) | formatCurrency("EUR") }}
              </div>
            </div>
          </div>
        </div>
        <div id="checkout">
          <form id="payment-form" method="POST" action="/checkout">
            <section>
              <div v-if="!displayResume">
                <h2>{{ $t("checkout.billing-information") }}</h2>

                <div class="header-options">
                  <div>
                    <input
                      v-model.trim="isCustomer"
                      type="radio"
                      id="customer"
                      :value="true"
                    />
                    <label for="customer">
                      {{ $t("generic_field_forms.customer") }}
                    </label>
                  </div>
                  <div>
                    <input
                      v-model.trim="isCustomer"
                      type="radio"
                      id="business"
                      :value="false"
                    />
                    <label for="business">
                      {{ $t("generic_field_forms.business") }}
                    </label>
                  </div>
                </div>

                <fieldset class="with-state">
                  <div class="inputForm w50">
                    <label for="name">
                      {{ $t("generic_field_forms.name") }}
                      <span v-if="isRequired($v.name.$params)">*</span>
                    </label>
                    <input
                      v-model.trim="$v.name.$model"
                      id="name"
                      name="name"
                      :class="$v.name.$error ? 'field w50 error' : 'field w50'"
                      :placeholder="$t('generic_field_forms.name')"
                      autocomplete="false"
                    />
                    <span v-if="$v.name.$error">
                      <span
                        :key="index"
                        v-for="(item, index) in Object.keys($v.name.$params)"
                      >
                        <span v-if="!$v.name[item]" class="errorMessage">
                          {{ $t("generic_field_forms_messages.name")[item] }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div class="inputForm w50">
                    <label for="last_name">
                      {{ $t("generic_field_forms.last_name") }}
                      <span v-if="isRequired($v.lastName.$params)">*</span>
                    </label>
                    <input
                      v-model.trim="$v.lastName.$model"
                      id="last_name"
                      name="last_name"
                      :class="
                        $v.lastName.$error ? 'field w50 error' : 'field w50'
                      "
                      :placeholder="$t('generic_field_forms.last_name')"
                      autocomplete="false"
                    />
                    <span v-if="$v.lastName.$error">
                      <span
                        :key="index"
                        v-for="(item, index) in Object.keys(
                          $v.lastName.$params
                        )"
                      >
                        <span v-if="!$v.lastName[item]" class="errorMessage">
                          {{
                            $t("generic_field_forms_messages.last_name")[item]
                          }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div class="inputForm">
                    <label for="address">
                      {{ $t("generic_field_forms.address") }}
                      <span v-if="isRequired($v.address.$params)">*</span>
                    </label>
                    <input
                      v-model.trim="$v.address.$model"
                      id="address"
                      name="address"
                      :class="$v.address.$error ? 'error' : ''"
                      :placeholder="$t('generic_field_forms.address')"
                      autocomplete="false"
                    />
                    <span v-if="$v.address.$error">
                      <span
                        :key="index"
                        v-for="(item, index) in Object.keys($v.address.$params)"
                      >
                        <span v-if="!$v.address[item]" class="errorMessage">
                          {{ $t("generic_field_forms_messages.address")[item] }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div class="inputForm w50">
                    <label for="city">
                      {{ $t("generic_field_forms.city") }}
                      <span v-if="isRequired($v.city.$params)">*</span>
                    </label>
                    <input
                      v-model.trim="$v.city.$model"
                      id="city"
                      name="city"
                      :class="$v.city.$error ? 'error' : ''"
                      :placeholder="$t('generic_field_forms.city')"
                      autocomplete="false"
                    />
                    <span v-if="$v.city.$error">
                      <span
                        :key="index"
                        v-for="(item, index) in Object.keys($v.city.$params)"
                      >
                        <span v-if="!$v.city[item]" class="errorMessage">
                          {{ $t("generic_field_forms_messages.city")[item] }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div class="inputForm w50">
                    <label for="state">
                      {{ $t("generic_field_forms.state") }}
                      <span v-if="isRequired($v.state.$params)">*</span>
                    </label>
                    <input
                      v-model.trim="$v.state.$model"
                      id="state"
                      name="state"
                      :class="$v.state.$error ? 'error' : ''"
                      :placeholder="$t('generic_field_forms.state')"
                      autocomplete="false"
                      v-if="country !== 'ES'"
                    />
                    <select
                      v-model.trim="$v.state.$model"
                      name="state"
                      :placeholder="$t('generic_field_forms.state')"
                      :class="$v.state.$error ? 'error' : ''"
                      v-else
                    >
                      <option value="" disabled>
                        {{ $t("generic_field_forms.select_state") }}
                      </option>
                      <option
                        v-for="item in spanishProvinces"
                        :value="item.name"
                        :key="item.name"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <span v-if="$v.state.$error">
                      <span
                        :key="index"
                        v-for="(item, index) in Object.keys($v.state.$params)"
                      >
                        <span v-if="!$v.state[item]" class="errorMessage">
                          {{ $t("generic_field_forms_messages.state")[item] }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div class="inputForm w50">
                    <label for="postal_code">
                      {{ $t("generic_field_forms.postal_code") }}
                      <span v-if="isRequired($v.postalCode.$params)">*</span>
                    </label>
                    <input
                      v-model.trim="$v.postalCode.$model"
                      id="postal_code"
                      name="postal_code"
                      :class="$v.postalCode.$error ? 'error' : ''"
                      :placeholder="$t('generic_field_forms.postal_code')"
                      autocomplete="false"
                    />

                    <span v-if="$v.postalCode.$error">
                      <span
                        :key="index"
                        v-for="(item, index) in Object.keys(
                          $v.postalCode.$params
                        )"
                      >
                        <span v-if="!$v.postalCode[item]" class="errorMessage">
                          {{
                            $t("generic_field_forms_messages.postal_code")[item]
                          }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div class="inputForm w50">
                    <label for="country">
                      {{ $t("generic_field_forms.country") }}
                      <span v-if="isRequired($v.country.$params)">*</span>
                    </label>
                    <select
                      v-model.trim="$v.country.$model"
                      name="country"
                      :placeholder="$t('generic_field_forms.country')"
                      :class="$v.country.$error ? 'error' : ''"
                    >
                      <option value="" disabled>
                        {{ $t("generic_field_forms.select_country") }}
                      </option>
                      <option
                        v-for="item in countriesList"
                        :value="item.code"
                        :key="item.code"
                        :selected="item.code === country ? 'selected' : ''"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <span v-if="$v.country.$error">
                      <span
                        :key="index"
                        v-for="(item, index) in Object.keys($v.country.$params)"
                      >
                        <span v-if="!$v.country[item]" class="errorMessage">
                          {{ $t("generic_field_forms_messages.country")[item] }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div class="inputForm w50">
                    <label for="vat">
                      {{ $t("generic_field_forms.vat") }}
                      <span v-if="isRequired($v.vat.$params)">*</span>
                    </label>
                    <input
                      v-model.trim="$v.vat.$model"
                      id="vat"
                      name="vat"
                      :class="$v.vat.$error ? 'error' : ''"
                      :placeholder="$t('generic_field_forms.vat')"
                      autocomplete="false"
                    />
                    <span v-if="$v.vat.$error">
                      <span
                        :key="index"
                        v-for="(item, index) in Object.keys($v.vat.$params)"
                      >
                        <span v-if="!$v.vat[item]" class="errorMessage">
                          {{ $t("generic_field_forms_messages.vat")[item] }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div class="inputForm w50">
                    <label for="phone">
                      {{ $t("generic_field_forms.phone") }}
                      <span v-if="isRequired($v.phone.$params)">*</span>
                    </label>
                    <input
                      v-model.trim="$v.phone.$model"
                      id="phone"
                      name="phone"
                      :class="$v.phone.$error ? 'field w50 error' : 'field w50'"
                      :placeholder="$t('generic_field_forms.phone')"
                      autocomplete="false"
                    />
                    <span v-if="$v.phone.$error">
                      <span
                        :key="index"
                        v-for="(item, index) in Object.keys($v.phone.$params)"
                      >
                        <span v-if="!$v.phone[item]" class="errorMessage">
                          {{ $t("generic_field_forms_messages.phone")[item] }}
                        </span>
                      </span>
                    </span>
                  </div>
                </fieldset>
                <button
                  class="payment"
                  :disabled="$v.$invalid || loadingPayment"
                  @click.prevent="addBillingData()"
                >
                  {{ $t("generic_field_forms.continueToPay") }}
                </button>
              </div>

              <!-- Display payment form only if billing is filled in -->
              <section class="payment-mobile" v-else>
                <b2b-billing-data
                  :name="name"
                  :lastName="lastName"
                  :address="address"
                  :postalCode="postalCode"
                  :city="city"
                  :state="state"
                  :country="country"
                  :vat="vat"
                  :phone="phone"
                  v-on:edit-billing-data="displayResume = !displayResume"
                  v-if="displayResume && loaded"
                ></b2b-billing-data>

                <!-- TODO: temporarily, disable PT and FR from PaymentLink -->
                <b2b-payment-method
                  @paymentMethod="paymentMethodListener"
                  @cardError="cardErrorListener"
                  :hasEmail="!!metadata.email"
                  :paymentInstance="paymentInstance"
                  :inPayment="loadingPayment"
                  :disableDirectDebit="disableDirectDebit"
                >
                </b2b-payment-method>

                <div class="check-conditions">
                  <input v-model="conditionsAccepted" type="checkbox" />
                  {{ $t("generic_field_forms.conditions1") }} &nbsp;
                  <a @click.prevent="showModal = true">
                    {{ $t("generic_field_forms.conditions2") }}
                  </a>
                  &nbsp; {{ $t("generic_field_forms.conditions3") }}
                </div>

                <div class="check-conditions">
                  <button
                    class="payment"
                    @click.prevent="makePayment()"
                    :disabled="!conditionsAccepted || loadingPayment"
                  >
                    <b2b-loading :loading="loadingPayment">
                      {{ $t("generic_field_forms.pay") }}
                      {{ (metadata.deposit / 100) | formatCurrency("EUR") }}
                    </b2b-loading>
                  </button>
                </div>
              </section>
            </section>
          </form>
        </div>
      </main>
      <b2b-finishing-page
        :message="finishingMessage"
        :cautionIcon="alertMessage"
        :amount="metadata.deposit / 100"
        v-if="shouldDisplayFinishingPage"
      ></b2b-finishing-page>
    </div>
    <b2b-loading-page v-show="loading"></b2b-loading-page>
  </div>
</template>

<script>
import api from "@/api";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import { loadLanguageAsync } from "@/i18n";
import spainProvinces from "@/utils/spainCities.js";
import B2bBillingData from "@/components/B2bPaymentLink/B2bBillingData.vue";
import "@adyen/adyen-web/dist/adyen.css";
import B2bLoading from "@/components/B2bLoading/B2bLoading.vue";
import B2bLoadingPage from "@/components/B2bLoading/B2bLoadingPage.vue";
import {
  // validateVat,
  validateZipCode,
  // validatePhoneNumber,
} from "@/utils/validations";
import B2bPaymentFinishPage from "@/components/B2bPaymentLink/B2bPaymentFinishPage.vue";
import { sentryException } from "@/services/sentry";
import B2bPaymentLinkContract from "@/components/B2bPaymentLink/B2bPaymentLinkContract.vue";
import B2bPaymentMethodVue from "@/components/B2bPaymentLink/B2bPaymentMethod.vue";
import Payment from "@/utils/paymentMetadata.js";

export default {
  name: "CheckoutChargebee",
  components: {
    "b2b-payment-link-contract": B2bPaymentLinkContract,
    "b2b-billing-data": B2bBillingData,
    "b2b-loading": B2bLoading,
    "b2b-finishing-page": B2bPaymentFinishPage,
    "b2b-loading-page": B2bLoadingPage,
    "b2b-payment-method": B2bPaymentMethodVue,
  },
  async mounted() {
    // Inject hubspot script to track secuences
    this.injectHubspotScript();

    // Check if we are under the new domain (temporal approach)
    const currentSite = this.$route.params.site;
    await this.getSiteLanguage(this.$route.params.site);

    // Initialize site language
    loadLanguageAsync(this.siteLanguage);

    try {
      // Load payment class to inject the chargebee instance
      this.paymentInstance = new Payment(this.siteLanguage, currentSite);

      // Initialize countries list
      !this.countriesList.length && (await this.getCountriesList());

      // Get payment link information
      await this.fetchPaymentLinkMetadata();

      // Ensure this payment link is valid
      const isValidPaymentLink = this.isValidPaymentLink();

      // Check if this payment link is already paid to display the message
      if (isValidPaymentLink) {
        window.logger.info("PL DETAILS ", this.metadata);

        // Render the main component
        this.shouldDisplayForm = true;

        // Mark this component as loaded
        this.loaded = true;
      } else {
        // throw new Error("Invalid domain for this link");
        this.shouldDisplayFinishingPage = true;
        this.shouldDisplayForm = false;
      }
    } catch (error) {
      window.logger.error("ERROR ", error);
      this.shouldDisplayForm = false;
      this.shouldDisplayFinishingPage = true;
      this.finishingMessage = this.$t("checkout.wrong_link");
      this.alertMessage = true;
    }

    // Drop spinner
    this.loading = false;
  },
  data() {
    return {
      alertMessage: false,
      finishingMessage: null,
      shouldDisplayFinishingPage: false,
      shouldDisplayForm: false,
      loadingPayment: false,
      loaded: false,
      conditionsAccepted: false,
      displayResume: false,
      cbInstance: null,
      paymentInstance: null,
      loading: true,
      showModal: false,
      metadata: {
        deposit: 0, // In cents
        with_iban: false,
      },
      sites: {
        net: "PT",
        io: "FR",
        es: "ES",
        pt: "PT",
        fr: "FR",
        it: "IT",
      },
      name: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      vat: "",
      postalCode: "",
      country: "",
      phone: "",
      errors: {},
      errorMessage: "",
      paymentMethod: null,
      currentDomain: "",
      isCustomer: true,
      bannerRange: {
        startDate: new Date(2022, 10, 7).toISOString(),
        endDate: new Date(2022, 10, 30).toISOString(),
      },
    };
  },
  computed: {
    spanishProvinces() {
      return spainProvinces;
    },
    ...mapState({
      countriesList: (state) => state.contracts.countriesList,
      siteLanguage: (state) => state.languages.siteLanguage,
    }),
    disableDirectDebit() {
      return !this.metadata.with_iban || this.currentDomain !== "ES";
    },
    shouldDisplayBanner() {
      // Display banner only until 30 of November
      const today = new Date().toISOString();

      return (
        this.bannerRange.startDate <= today && today <= this.bannerRange.endDate
      );
    },
  },
  validations: {
    name: {
      required,
    },
    lastName: {
      required,
    },
    address: {
      required,
    },
    city: {
      required,
    },
    state: {
      required,
    },
    vat: {
      required,
      // validateVat,
    },
    phone: {
      // required,
      // validatePhoneNumber,
    },
    postalCode: {
      validateZipCode,
      required,
    },
    country: {
      required,
    },
  },
  watch: {
    /**
     * Watch on country changes to reset state value and force the form validation
     */
    country: function (newValue, oldValue) {
      if (oldValue !== newValue && newValue === "ES" && oldValue) {
        this.state = "";
      }
    },
  },
  methods: {
    /**
     * Get card component to display and propagate the error in the parent
     */
    cardErrorListener(error) {
      // Display the error to user
      this.$toastr.e(
        this.$t("checkout.adyen_error"),
        this.$t("checkout.error_payment_title")
      );

      sentryException(new Error(error));
    },
    /**
     * Get child payment component metadata to perform the real payment against chargebee using the right gateway
     */
    paymentMethodListener(paymentMethodObject) {
      this.paymentMethod = paymentMethodObject;
    },
    /**
     * Inject dynamically hubspot script only in this page
     */
    injectHubspotScript() {
      const plugin = document.createElement("script");
      plugin.setAttribute("src", process.env.VUE_APP_HUBSPOT_SCRIPT);
      document.head.appendChild(plugin);
    },
    /**
     * Return if this payment link is valid or not
     */
    isValidPaymentLink() {
      if (this.metadata.is_paid) {
        this.finishingMessage = this.$t("checkout.is_paid");
        return false;
      }

      const now = new Date();
      const validUntil = new Date(this.metadata.valid_until);

      if (now > validUntil) {
        this.finishingMessage = this.$t("checkout.expired");
        this.alertMessage = true;
        return false;
      }

      const site = this.metadata.site.toUpperCase();
      this.currentDomain =
        this.sites[
          this.$route.params.site || window.location.hostname.split(".").pop()
        ] || "ES";

      window.logger.info("SITE ", site, this.currentDomain);
      // Restrict payment link to valid domain for production environment
      if (site !== this.currentDomain) {
        this.finishingMessage = this.$t("checkout.wrong_link");
        this.alertMessage = true;
        return false;
      }

      return true;
    },
    /**
     * Make the payment against adyen
     */
    async makePayment() {
      // Check if payment method state is valid
      if (!this.paymentMethod || !this.paymentMethod.metadata.runValidation()) {
        return null;
      }

      // Disable button
      this.loadingPayment = true;

      // Everything is OK, get the billing data and card instance
      try {
        const email = this.metadata.email;
        const integrityKey = this.$route.params.key;

        // Perform the payment
        await this.paymentMethod.metadata.run(email, integrityKey, {
          name: this.name,
          lastName: this.lastName,
          address: this.address,
          city: this.city,
          state: this.state,
          zip: this.postalCode,
          country: this.country,
          vat: this.vat,
          phone: this.phone,
          isCustomer: this.isCustomer,
        });

        this.loadingPayment = false;
        this.shouldDisplayForm = false;
        this.shouldDisplayFinishingPage = true;
      } catch (error) {
        this.loadingPayment = false;

        // Show error message with custom message
        if (error.response?.status === 410) {
          this.shouldDisplayForm = false;
          this.shouldDisplayFinishingPage = true;
          this.finishingMessage = this.$t("checkout.adyen_error");
        } else {
          // Display the error to user
          this.$toastr.e(
            error.response?.data?.errors?.reason ||
              this.$t("checkout.adyen_error"),
            this.$t("checkout.error_payment_title")
          );
        }
        sentryException(new Error(error));
      }
    },
    /**
     * Get payment link metadata
     */
    async fetchPaymentLinkMetadata() {
      const paymentLinkMetadata = await api.payments.getPaymentLinkDetails(
        this.$route.params.key
      );
      this.metadata = { ...paymentLinkMetadata.data };
    },
    /**
     * Helper method to display * for required inputs
     */
    isRequired(validation) {
      return validation && validation.required !== undefined;
    },
    ...mapActions({
      getCountriesList: "contracts/getCountriesList",
      getSiteLanguage: "languages/getSiteLanguage",
    }),
    addBillingData() {
      this.$v.$touch();

      // Display resume block if billing data are valid
      if (!this.$v.$invalid) {
        this.displayResume = true;
      }
    },
    /**
     * Used by vuelidate to validate the vat number
     */
    validateVatNumber(vatNumber) {
      return new Promise((resolve, reject) => {
        if (!this.country || !vatNumber) {
          resolve();
          return false;
        }

        const cbInstance = this.paymentInstance.getChargebeeInstance();
        cbInstance.load("functions").then(() => {
          cbInstance.vat
            .validateVat({
              country: this.country,
              vat_number: vatNumber,
            })
            .then((data) => {
              resolve(data.status);
            })
            .catch((error) => {
              reject(error);
              throw new Error(error);
            });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/app";
* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  font-size: 15px;
  line-height: 1.4em;
}

/* Overall Container */

#main {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  transition: width 0.3s ease-in-out;
}

/* Checkout */
#pay-detail,
#checkout {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0;
  height: 100%;
}

#checkout {
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear 0.5s;
  visibility: hidden;
}

.check-conditions {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 1rem;
  font-size: 0.81rem;
  font-weight: 500;

  & > input {
    cursor: pointer;
    height: 1rem;
    width: 1.2rem;
    min-width: 1.2rem;
    border-radius: 0.2rem;
    margin-right: 0.3rem;
  }

  & > a {
    font-size: 0.81rem;
    color: #4e6cff;
    font-weight: 600;
    cursor: pointer;
  }

  & .payment {
    margin-top: 0;
  }

  span {
    color: #fff;
  }
}

.check-conditions:last-child {
  margin-top: 0.5rem;
}

#pay-detail {
  background-color: #f2f3fd;
  height: 100%;

  hr {
    border: 1px solid #e6eaed;
    width: 30px;
    margin: 0 0 32px 0;
  }

  h1.logo {
    background-image: url("../assets/logo2.png");
    background-size: contain;
    // height: 46px;
    // width: 142px;
    margin-left: 0.6rem;
    margin-bottom: 0;

    & span {
      display: none;
    }
  }
  .detail-wrapper {
    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .amount-data {
    width: 124px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pay {
      font-size: 1.2rem;
      font-weight: 500;
    }
    .amount {
      font-size: 1.5rem;
      color: #454d56;
      font-weight: 600;
    }
  }

  .banner {
    text-align: center;
    font-weight: 800;

    p {
      font-size: 1.5rem;
    }
  }
}

#main.checkout #checkout {
  visibility: visible;
  opacity: 1;
}

section {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

h1 {
  margin: 0 0 20px 0;
  font-size: 20px;
  font-weight: 500;
}

h2 {
  // margin: 15px 0;
  color: #32325d;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-size: 13px;
  font-weight: 500;
}

#payment-form {
  padding: 1.5rem 2rem 2rem;
  border-radius: 0.25rem;
  margin: 2rem;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
    0px 2px 4px rgba(96, 97, 112, 0.16);
  width: 95%;
}
fieldset {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  label,
  select,
  input {
    margin-top: 0;
    width: 100%;

    span {
      color: red;
    }
  }
  input[type="checkbox"] {
    cursor: pointer;
    height: 1rem;
    width: 1.2rem;
    min-width: 1.2rem;
    border-radius: 0.2rem;
    transition-property: all;
  }
  select,
  input {
    height: 36px;
    outline: none;
    padding: 0 0.7rem;

    border: 1px solid #d1d1d1;
    border-radius: 0.25rem;
    font-size: 1rem;
    color: #4c5b67;
    background: #fff;
    font-size: 13px;
  }
  label {
    font-size: 0.81rem;
    font-weight: 500;
  }
  .w50 {
    width: calc(50% - 0.5rem);
  }
  .error {
    border: 1px solid #d10244;
  }
}

.inputForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  width: 100%;

  & span,
  & label {
    font-size: 0.81rem;
    font-weight: 500;
    margin-top: 0.15rem;
  }

  & select {
    cursor: pointer;
  }

  .errorMessage {
    color: #d10244;
  }

  & input {
    width: 100%;
  }
}

button {
  display: block;
  color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 0;
  font-weight: 700;
  width: 100%;
  height: 40px;
  outline: none;
  cursor: pointer;
}

button:focus {
  background: #555abf;
}

button:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
}

button:active {
  background: #43458b;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.2;
}

button.payment {
  margin-top: 1rem;
  height: 46px;
  background: #4760ff;
}

#country {
  display: flex;
  align-items: center;
}

#country select {
  margin: 0 -15px 0 -30px;
  padding: 0 15px 0 30px;
}

.element-errors {
  display: inline-flex;
  height: 20px;
  margin: 15px auto 0;
  padding-left: 20px;
  color: #d10244;
  opacity: 0;
  transform: translateY(10px);
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  background: url(/images/error.svg) center left no-repeat;
  background-size: 15px 15px;
}

.element-errors.visible {
  opacity: 1;
  transform: none;
}

.card-number {
  padding-left: 8px;
  white-space: nowrap;
  font-family: Source Code Pro, monospace;
  color: #0d2b3e;
  font-weight: 500;
}

.card-number span {
  display: inline-block;
  width: 8px;
}

/* Media Queries */

@media only screen and (max-width: 1310px) {
  .check-conditions {
    display: block;
  }
}
@media only screen and (max-width: 1024px) {
  #payment-request {
    padding-top: 0;
    min-height: 80px;
  }
  #pay-detail {
    .amount-data {
      display: block;
      padding-bottom: 1rem;
    }
  }
}

@media only screen and (max-width: 900px) {
  #main {
    display: flex;
    flex-direction: column;
  }

  #payment-request {
    min-height: 0;
    padding-right: 15px;
    padding-left: 15px;
  }
  #payment-form {
    margin: 0;
    border-width: 2px 0 0 0;
    border-radius: 0;
    padding: 0.4rem;
    width: 98%;
  }
  fieldset {
    margin-bottom: 15px;
  }
  fieldset label.name,
  fieldset label.last_name,
  fieldset label.state,
  fieldset label.zip {
    display: flex;
    width: inherit;
    padding: 10px 0;
  }
  p.instruction {
    margin-top: -12px;
    font-size: 14px;
  }
  p.tip {
    margin-bottom: 0;
    font-size: 13px;
  }
  #country::before {
    display: none;
  }
  #checkout {
    margin-bottom: 0;
  }
}

.payment-method {
  margin-top: 2rem;
}

/** Popup clases */
.popup_button {
  display: inline-block;
  background-color: #4e6cff;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  &:hover {
    box-shadow: 6px 6px rgba(0, 0, 0, 0.6);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Header options */
.header-options {
  justify-content: flex-end;
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  cursor: pointer;

  & div {
    justify-content: flex-end;
    justify-items: center;
    vertical-align: middle;
    padding: 0.5rem;
    border: 1px solid #d1d1d1;
    border-radius: 0.25rem;
    display: inline-flex;
    align-items: center;

    & label {
      font-size: 1rem;
      color: #4c5b67;
      background: #fff;
      font-size: 0.81rem;
      font-weight: 500;
      cursor: pointer;
    }

    &:first-child {
      margin-right: 0.5rem;
    }

    & input {
      margin: 0 0.25rem 0;
      cursor: pointer;
    }
  }
}
</style>
